import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend'
import useMatchMedia from 'react-use-match-media'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { PATHS } from '../../routing/paths'
import { usePopupState } from '../../hooks/usePopupState'
import { useKeysManager } from '../../hooks/useKeysManager'

import {
  PopupFortuneGift,
  PopupTicketGift,
  PopupCouponGift,
  PopupAllDaysOpened,
  PopupNotHaveKeys,
  PopupGiftError,
  PopupCalendarError,
  PopupPromocodeGift,
} from '../../ui/organisms'
import { CalendarMobile } from './CalendarMobile'
import { CalendarDesktop } from './CalendarDesktop'
import { GlobalStyle } from 'ui/settings/global'
import {
  clearGiftReceivingError,
  giftReceiving,
  setSingleGiftData,
} from 'store/slices/calendarSlice'
import { useCalendarManager } from 'hooks/useCalendarManager'
import { CALENDAR_ITEM_STATUS, GIFT_TYPE } from 'constants/calendarGifts'
import { removeKeyById } from 'store/slices'
import { useCalendarsManager } from 'hooks/useCalendarsManager'
import { converToRussianDateFormat } from 'utils/converToRussianDateFormat'
import { useWideViewport } from './useWideViewport'
import { useUserFirstVisit } from 'hooks/useUserFirstVisit'

const AMOUNT_OF_CALENDAR_BG = 3
const AMOUNT_OF_GIFTS_IN_CALENDAR = 12
const DEFAULT_CALENDAR_ID = 1

export const Calendar = () => {
  const dispatch = useDispatch()
  const [isBoardLoading, setBoardLoading] = useState(true)
  const [isAllDaysOpened, setIsAllDaysOpened] = useState(false)
  const isWideViewport = useWideViewport()
  let [searchParams] = useSearchParams()
  const { calendarDetails, isCalendarDetailsLoading, fetchCalendarDetails } =
    useCalendarManager()
  const navigate = useNavigate()
  const {
    keys,
    keysCount,
    isLoading: isKeysLoading,
    fetchKeys,
  } = useKeysManager({ isAutoLoad: false })
  const { calendars, isLoading: isCalendarsLoading } = useCalendarsManager()
  const urlSearchParams = useSelector(state => state.user.urlSearchParams)
  useUserFirstVisit()

  const {
    isOpen: isPopupGiftErrorOpen,
    onOpen: onPopupGiftErrorOpen,
    onClose: onPopupGiftErrorClose,
  } = usePopupState()

  const {
    isOpen: isPopupCalendarErrorOpen,
    onOpen: onPopupCalendarErrorOpen,
    onClose: onPopupCalendarErrorClose,
  } = usePopupState()

  const {
    data: fortuneGiftData,
    isOpen: isPopupFortuneGiftOpen,
    onOpen: onPopupFortuneGiftOpen,
    onClose: onPopupFortuneGiftClose,
  } = usePopupState()

  const {
    data: ticketGiftData,
    isOpen: isPopupTicketGiftOpen,
    onOpen: onPopupTicketGiftOpen,
    onClose: onPopupTicketGiftClose,
  } = usePopupState()

  const {
    data: couponGiftData,
    isOpen: isPopupCouponGiftOpen,
    onOpen: onPopupCouponGiftOpen,
    onClose: onPopupCouponGiftClose,
  } = usePopupState()

  const {
    data: promocodeGiftData,
    isOpen: isPopupPromocodeGiftOpen,
    onOpen: onPopupPromocodeGiftOpen,
    onClose: onPopupPromocodeGiftClose,
  } = usePopupState()

  const {
    isOpen: isPopupNotHaveKeysOpen,
    onOpen: onPopupNotHaveKeysOpen,
    onClose: onPopupNotHaveKeysClose,
  } = usePopupState()

  const {
    isOpen: isPopupAllDaysOpenedOpen,
    onOpen: onPopupAllDaysOpenedOpen,
    onClose: onPopupAllDaysOpenedClose,
  } = usePopupState()

  const onGiftPopupClose = () => {
    onPopupCouponGiftClose()
    onPopupPromocodeGiftClose()
    onPopupFortuneGiftClose()
    onPopupTicketGiftClose()

    if (isAllDaysOpened) {
      onPopupAllDaysOpenedOpen()
      setIsAllDaysOpened(false)
    }
  }

  const calendarId = useMemo(() => {
    return searchParams.get('item_id') || DEFAULT_CALENDAR_ID
  }, [searchParams])

  const calendarUpdate = async () => {
    if (!!calendarId) {
      setBoardLoading(true)

      try {
        await Promise.all([
          fetchCalendarDetails(calendarId).unwrap(),
          fetchKeys().unwrap(),
        ])
        dispatch(clearGiftReceivingError())
        setBoardLoading(false)
      } catch (error) {
        setBoardLoading(false)
        onPopupCalendarErrorOpen()
      }
    }
  }

  useEffect(() => {
    if (
      !!urlSearchParams?.session_id &&
      !!urlSearchParams?.user_id &&
      !isCalendarDetailsLoading &&
      !isKeysLoading
    ) {
      calendarUpdate()
    }
  }, [urlSearchParams?.session_id, urlSearchParams?.user_id])

  useEffect(() => {
    if (!isCalendarsLoading) {
      setBoardLoading(false)
    }
  }, [isCalendarsLoading])

  const onCalendarUpdateClick = async () => {
    onPopupGiftErrorClose()
    await calendarUpdate()
  }

  const calendarType = useMemo(() => {
    if (!!calendars && !!calendarId) {
      const result = Number(calendarId) % AMOUNT_OF_CALENDAR_BG
      if (result === 0) {
        return AMOUNT_OF_CALENDAR_BG
      } else {
        return result
      }
    }
    return null
  }, [calendarId, calendars])

  useEffect(() => {
    if (keysCount === 0) {
      onPopupNotHaveKeysOpen()
    }
  }, [keysCount])

  const onKeyDrop = async ({ keyId, index }) => {
    setBoardLoading(true)

    try {
      let openedGiftsAmount = 0
      calendarDetails.items.forEach(item => {
        if (item.status === CALENDAR_ITEM_STATUS.OPEN) {
          openedGiftsAmount++
        }
      })

      if (openedGiftsAmount === AMOUNT_OF_GIFTS_IN_CALENDAR - 1) {
        setIsAllDaysOpened(true)
      }

      await dispatch(
        giftReceiving({
          calendar_id: calendarId,
          item_index: index,
          key_id: keyId,
        })
      )
        .unwrap()
        .then(data => {
          dispatch(setSingleGiftData({ index, status: 'open', ...data }))
          dispatch(removeKeyById(keyId))
          giftPopupOpen(data)
        })

      setBoardLoading(false)
    } catch (error) {
      setBoardLoading(false)
      onPopupGiftErrorOpen()
    }
  }

  const onGiftBoxClick = async index => {
    if (keys && keys.length > 0) {
      const keyId = keys[0].id
      onKeyDrop({ keyId, index })
    }
  }

  const giftPopupOpen = calendarItem => {
    const giftType = calendarItem.gift.type

    if (giftType === GIFT_TYPE.COUPON) {
      onPopupCouponGiftOpen({
        couponValue: calendarItem.gift.value,
        couponDescription: calendarItem.gift.description,
      })
    } else if (giftType === GIFT_TYPE.PREDICTION) {
      onPopupFortuneGiftOpen({ fortuneValue: calendarItem.gift.value })
    } else if (giftType === GIFT_TYPE.TICKET) {
      onPopupTicketGiftOpen({
        ticketValue: calendarItem.gift.value,
        raffleDate: converToRussianDateFormat(calendarItem.gift.raffle_date),
      })
    } else if (giftType === GIFT_TYPE.PROMOCODE) {
      onPopupPromocodeGiftOpen({
        promocodeValue: calendarItem.gift.value,
        promocodeDescription: calendarItem.gift.description,
      })
    }
  }

  const onPopupAllDaysOpenedSubmit = () => navigate(PATHS.CALENDARS)

  return (
    <GlobalStyle>
      <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
        {isWideViewport ? (
          <CalendarDesktop
            calendarDetails={calendarDetails}
            calendarType={calendarType}
            isBoardLoading={isBoardLoading}
            onKeyDrop={onKeyDrop}
            calendarUpdate={calendarUpdate}
            giftPopupOpen={giftPopupOpen}
            onGiftBoxClick={onGiftBoxClick}
          />
        ) : (
          <CalendarMobile
            calendarDetails={calendarDetails}
            calendarType={calendarType}
            isBoardLoading={isBoardLoading}
            onKeyDrop={onKeyDrop}
            calendarUpdate={calendarUpdate}
            giftPopupOpen={giftPopupOpen}
            onGiftBoxClick={onGiftBoxClick}
          />
        )}

        <PopupFortuneGift
          {...fortuneGiftData}
          isOpened={isPopupFortuneGiftOpen}
          onClose={onGiftPopupClose}
        />
        <PopupTicketGift
          {...ticketGiftData}
          isOpened={isPopupTicketGiftOpen}
          onClose={onGiftPopupClose}
        />
        <PopupCouponGift
          {...couponGiftData}
          isOpened={isPopupCouponGiftOpen}
          onClose={onGiftPopupClose}
        />
        <PopupPromocodeGift
          {...promocodeGiftData}
          isOpened={isPopupPromocodeGiftOpen}
          onClose={onGiftPopupClose}
        />
        <PopupAllDaysOpened
          isOpened={isPopupAllDaysOpenedOpen}
          onClose={onPopupAllDaysOpenedClose}
          onSubmitClick={onPopupAllDaysOpenedSubmit}
        />
        <PopupNotHaveKeys
          isOpened={isPopupNotHaveKeysOpen}
          onClose={onPopupNotHaveKeysClose}
        />
        <PopupGiftError
          isOpened={isPopupGiftErrorOpen}
          onClose={onPopupGiftErrorClose}
          onSubmit={onCalendarUpdateClick}
        />
        <PopupCalendarError
          isOpened={isPopupCalendarErrorOpen}
          onClose={onPopupCalendarErrorClose}
          onSubmit={onCalendarUpdateClick}
        />
      </DndProvider>
    </GlobalStyle>
  )
}
