import { TextTitleSmallerHomeNewYearGame } from './TextTitleSmallerHomeNewYearGame'
import { TextTitleSmallerHomeTreasureIslandGame } from './TextTitleSmallerHomeTreasureIslandGame'

const TextTitleSmallerHomeByGameId = {
  1: TextTitleSmallerHomeNewYearGame,
  2: TextTitleSmallerHomeTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TextTitleSmallerHome = TextTitleSmallerHomeByGameId[gameId]
