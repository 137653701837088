import { Button, Text } from 'ui/atoms'
import { PopupInfo } from 'ui/molecules'
import { buttonProps } from './buttonProps'
import { contentProps } from './contentProps'

export const PopupActionEnd = ({ isOpened, onClose }) => (
  <PopupInfo isOpened={isOpened} onClose={onClose}>
    <Text {...contentProps}>
      Акция завершена. С её результатами вы можете ознакомиться в социальных
      сетях SPAR Калининград
    </Text>
    <Button {...buttonProps} onClick={onClose}>
      Продолжить
    </Button>
  </PopupInfo>
)
